// ProfessionalsView.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar } from '@mui/material';
import { useAuth } from 'Components/AuthContext';
import { ApiEndpoints } from 'Config/ApiConstants';
import { axiosClient } from 'Config/Axios';
import download from 'assets/download.png'; // Import the default image

const ProfessionalsView = () => {
  const { id } = useParams();
  const [professionals, setProfessionals] = useState([]);
  const { accessToken } = useAuth();

  useEffect(() => {
    const fetchCenterProfessionals = async () => {
      try {
        const response = await axiosClient.get(ApiEndpoints.GET_PROFESSIONALS(id), {
          headers: {
            'Authorization': `Bearer ${accessToken}` // Include the access token in the request headers
          }
        });

        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        setProfessionals(response.data.data.professionals);
      } catch (error) {
        console.error('Error fetching center professionals:', error);
      }
    };

    fetchCenterProfessionals();
  }, [id, accessToken]);

  if (!professionals.length) {
    return <Typography>Loading professionals...</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* <Typography variant="h4" gutterBottom>
        Spa Professionals
      </Typography> */}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Image
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Mobile Number
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Gender
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {professionals.map((professional, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Avatar
                    variant="square"
                    alt={`${professional.firstName} ${professional.lastName}`}
                    src={professional.images.thumbnail || download} // Use default image if no thumbnail
                    sx={{
                      width: 56,
                      height: 56,
                      border: '2px solid #fffefd', // Example border: 2px solid black
                    }}
                  />

                </TableCell>
                <TableCell>{`${professional.firstName} ${professional.lastName}`}</TableCell>
                <TableCell>{professional.mobileNumber}</TableCell>
                <TableCell>{professional.gender}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ProfessionalsView;
