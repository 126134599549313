import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardMedia, Divider, Tabs, Tab, Box, Chip, Paper, Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ServicesView from './ServicesView';
import ProfessionalsView from './ProfessionalsView';
import Account from './Account';
import Updateaccount from './Updateaccount';
import { ApiEndpoints } from 'Config/ApiConstants'
import { useParams, useNavigate } from 'react-router-dom';
import { axiosClient } from 'Config/Axios';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const CenterDetails = ({ center }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [centerStatus] = useState(center.status);
  const [activationMessage, setActivationMessage] = useState('');
  const { id } = useParams();
  const [showAccountAndUpdateTabs, setShowAccountAndUpdateTabs] = useState(false);
  // Get center ID from route parameters
  const navigate = useNavigate(); // Import useNavigate hook

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  useEffect(() => {
    // Check the payment modes when the component loads or when `center` data is updated
    if (center.paymentMethods && center.paymentMethods.includes('online')) {
      setShowAccountAndUpdateTabs(true); // Show the account and update tabs when 'online' is present
    } else {
      setShowAccountAndUpdateTabs(false); // Hide the account and update tabs otherwise
    }
  }, [center.paymentMethods]);
  const activateCenter = async () => {
    try {
      const centerDetailsResponse = await axiosClient.get(ApiEndpoints.GET_CENTER_DETAILS(id)); 
      const { paymentMethods } = centerDetailsResponse.data.data.center;
      
      console.log(paymentMethods);
  
      // Check if "cash" is the only payment mode
      if (paymentMethods.length === 1 && paymentMethods[0] === 'cash') {
        const response = await axiosClient.post(ApiEndpoints.POST_CENTER_ACTIVATE(id));
        if (response.status === 200) {
          setActivationMessage('Center successfully activated for cash payment mode.');
          navigate('/dashboard');
        } else {
          setActivationMessage('Failed to activate the center.');
        }
        return; 
      }
  
      // First GET API call: Get Razorpay account details
      const accountResponse = await axiosClient.get(ApiEndpoints.GET_CENTER_ACCOUNT(id));
      const { razorpayAccountId, razorpayProductId } = accountResponse.data.data;
  
      // Check Razorpay account details
      if (!razorpayAccountId || !razorpayProductId) {
        setActivationMessage('Razorpay account details are missing.');
        return;  
      }
  
      // Second GET API call: Get Razorpay account activation status
      const bankResponse = await axiosClient.get(ApiEndpoints.GET_BANK_DETAILS(razorpayAccountId, razorpayProductId));
      const activationStatus = bankResponse.data.data.activation_status;
  
      if (activationStatus === 'activated') {
        // Final API call to activate center
        const response = await axiosClient.post(ApiEndpoints.POST_CENTER_ACTIVATE(id));
  
        if (response.status === 200) {
          setActivationMessage('Center successfully activated.');
          navigate('/dashboard');
        }
      } else {
        setActivationMessage('Center activation failed: Account is not activated yet.');
      }
    } catch (error) {
      console.error('Error during activation process:', error);
      setActivationMessage('An error occurred while activating the center.');
    }
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper sx={{ p: 2, mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          {center.name}
        </Typography>
      </Paper>

      {/* Tabs Section */}
      <Box sx={{ width: '100%', mt: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="center details tabs">
            <Tab label="Center Details" {...a11yProps(0)} />
            <Tab label="Services" {...a11yProps(1)} />
            <Tab label="Professionals" {...a11yProps(2)} />
            {showAccountAndUpdateTabs && <Tab label="Accounts" {...a11yProps(3)} />}
            {showAccountAndUpdateTabs && <Tab label="Updateaccount" {...a11yProps(4)} />}
          </Tabs>
        </Box>
        <CustomTabPanel value={tabIndex} index={0}>
          <Grid container spacing={2} direction="column">
            {/* Home Image - Spanning Full Width */}
            <Grid item xs={12}>
              <Card elevation={3} sx={{ borderRadius: '12px', overflow: 'hidden', boxShadow: 3 }}>
                <CardMedia
                  component="img"
                  image={center.images.home}
                  alt={`${center.name} home image`}
                  sx={{ height: '600px', width: '100%', objectFit: 'cover' }}
                />
              </Card>
            </Grid>

            {/* Content and Thumbnail Below the Home Image */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* Left Side: Content */}
                <Grid item xs={12} md={8}>
                  <Card elevation={3} sx={{ p: 2, borderRadius: '12px', height: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <PhoneIcon sx={{ mr: 1 }} />
                      <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
                        Mobile Number: {center.mobileNumber}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <CalendarTodayIcon sx={{ mr: 1 }} />
                      <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
                        Created At: {new Date(center.createdAt).toLocaleString()}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <CalendarTodayIcon sx={{ mr: 1 }} />
                      <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
                        Updated At: {new Date(center.updatedAt).toLocaleString()}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <LocationOnIcon sx={{ mr: 1 }} />
                      <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
                        Address: {center.address}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <PaymentIcon sx={{ mr: 1 }} />
                      <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
                        Payment Methods: {center.paymentMethods.join(', ')}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <CheckCircleIcon sx={{ mr: 1, color: centerStatus === 'active' ? 'success.main' : 'text.secondary' }} />
                        <Typography variant="body2" color="text.secondary" sx={{ fontSize: 'inherit' }}>
                          Status: <Chip label={centerStatus} color={centerStatus === 'active' ? 'success' : 'default'} />
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                          variant={centerStatus === 'active' ? 'contained' : 'outlined'}
                          color="success"

                          onClick={() => activateCenter('active')}
                          disabled={centerStatus === 'active'}
                        >
                          Activate Center
                        </Button>
                        {activationMessage && <p>{activationMessage}</p>}
                      </Box>
                    </Box>
                  </Card>
                </Grid>

                {/* Right Side: Thumbnail Image */}
                <Grid item xs={12} md={4}>
                  <Card elevation={3} sx={{ borderRadius: '12px', overflow: 'hidden', boxShadow: 3 }}>
                    <CardMedia
                      component="img"
                      image={center.images.thumbnail}
                      alt={`${center.name} thumbnail image`}
                      sx={{ height: '300px', width: '100%', objectFit: 'cover' }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <ServicesView /> {/* Render ServicesView inside this tab */}
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={2}>
          <ProfessionalsView />
        </CustomTabPanel>
        {showAccountAndUpdateTabs && (
          <>
            <CustomTabPanel value={tabIndex} index={3}>
              <Typography variant="body1">
                <Account />
              </Typography>
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={4}>
              <Typography variant="body1">
                <Updateaccount />
              </Typography>
            </CustomTabPanel>
          </>
        )}

      </Box>
    </Container>
  );
};

const View = () => {
  const { id } = useParams(); // Extract the center ID from the URL
  const [centerDetails, setCenterDetails] = useState(null);

  useEffect(() => {
    const fetchCenterDetails = async () => {
      try {
        const response = await axiosClient.get(ApiEndpoints.GET_CENTER_DETAILS(id));

        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        setCenterDetails(response.data.data);
      } catch (error) {
        console.error('Error fetching center details:', error);
      }
    };

    fetchCenterDetails();
  }, [id]);

  if (!centerDetails) {
    return <Typography>Loading...</Typography>;
  }

  return <CenterDetails center={centerDetails.center} />;
};

export default View;