// export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ApiEndpoints = {
    LOGIN: "user/login",
    REFRESH: "admin/refresh",
    GET_CENTER: (itemsPerPage) => {
       
        return `admin/center/list?itemsPerPage=${itemsPerPage}`
    },
    GET_CENTER_DETAILS: (id) => `admin/center/details?centerId=${id}`,
    GET_PROFESSIONALS: (id) => `admin/center/details?centerId=${id}`,
    // POST_ACCOUNTS: (id) => `center/details?centerId=${id}`
    POST_ACCOUNTS: (id) => `admin/${id}/link/account`,
   
    POST_DETAILS: (id, accountId) => `admin/${id}/${accountId}/bank/details`, 
    // Updated method
    GET_ACCOUNT_DETAILS: (accountId) => `admin/linked/account/${accountId}`,
    GET_CENTER_ACCOUNT: (id) => `admin/center/${id}/account/details`,
    GET_BANK_DETAILS: (accountId,productId) => `admin/bank/details/${accountId}/${productId}`,
    PUT_ACCOUNTS: (accountId) => `admin/linked/account/${accountId}`,
    PUT_BANK_DETAILS: (accountId,productId) =>`admin/bank/details/${accountId}/${productId}`,
    POST_CENTER_ACTIVATE: (id) => `admin/center/${id}/activate`

}