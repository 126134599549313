// ServicesView.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
} from '@mui/material';
import { axiosClient } from 'Config/Axios';
import { ApiEndpoints } from 'Config/ApiConstants';
import download from 'assets/download.png';

const ServicesView = () => {
  const { id } = useParams();
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchCenterServices = async () => {
      try {

        const response = await axiosClient.get(ApiEndpoints.GET_CENTER_DETAILS(id))



        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // const result = await response.json();
        setServices(response.data.data.services);
      } catch (error) {
        console.error('Error fetching center services:', error);
      }
    };

    fetchCenterServices();
  }, [id]);

  if (!services.length) {
    return <Typography>Loading services...</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <TableContainer >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Thumbnail
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Service Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Duration (mins)
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Price (RS)
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Avatar
                    variant="square"
                    src={service.images.thumbnail || download}
                    alt={`${service.name} image`}
                    sx={{ width: 80, height: 80,border:'2px solid #fffefd' }}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="h6">{service.name}</Typography>
                </TableCell>
                <TableCell>{service.duration}</TableCell>
                <TableCell>{service.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>


  );
};

export default ServicesView;
