import React from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from './Components/AuthContext';
import Dashboard from "./Components/Dashboard/Dashboard";
import Layout from "./Components/Layout/Layout";
import Transactions from "./Components/Transaction/Transactions";
import Login from "./Components/Login/Login";
import { themeSettings } from "theme";
// import ProtectedRoute from "./Components/ProtectedRoute";
import ProtectedRoute from 'Components/ProtectedRoute ';
import { useSelector } from 'react-redux';
import View from './Components/View/View';
import ServicesView from './Components/View/ServicesView';
import ProfessionalsView from './Components/View/ProfessionalsView';
import Account from 'Components/View/Account';
import Updateaccount from 'Components/View/Updateaccount';

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = React.useMemo(() => createTheme(themeSettings(mode)), [mode]);
  

  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route element={<ProtectedRouteWrapper />}>
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/inactivecenter" element={<Transactions />} />
                <Route path="/center/:id" element={<View />}
                 ></Route>
                <Route path="/center/:id/services" element={<ServicesView />} />
                <Route path="/center/:id/professionals" element={<ProfessionalsView />} />
                <Route path='/center/:id/account' element={<Account/>}/>
                <Route path='/center/:id/update' element={<Updateaccount/>}/>
              </Route>
            </Routes>
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

const ProtectedRouteWrapper = () => {
  const { isAuthenticated } = useAuth();

  return (
    <ProtectedRoute isAuthenticated={isAuthenticated}>
      <Layout />
    </ProtectedRoute>
  );
};

export default App;
