import React from "react";

import {
  Box,
} from "@mui/material";
const FlexBetween = ({ children }) => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>{children}</div>;
const Header = ({ title, subtitle }) => (
  <div>
    <h1>{title}</h1>
    <p>{subtitle}</p>
  </div>
);
const Dashboard = () => {
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="DASHBOARD" subtitle="Coming soon Dashboard" />
      </FlexBetween>
    </Box>
  );
};

export default Dashboard;
