
import React, { useState } from 'react';
import { Box, Button, TextField, Paper, Typography } from '@mui/material';
import logo from 'assets/logo.png';
import axios from 'axios';
import { useAuth } from 'Components/AuthContext';
import { ApiEndpoints } from '../../Config/ApiConstants';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const { login } = useAuth(); 
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setShowGif(true);

    try {
      const params = new URLSearchParams();
      params.append('email', email);
      params.append('password', password);
      // const url = ApiEndpoints.LOGIN
      // console.log(process.env.REACT_APP_BASE_URL)
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${ApiEndpoints.LOGIN}`, params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
      });
  
      if (response.data && response.data.data && response.data.data.accessToken) {
          const accessToken = response.data.data.accessToken;
          const refreshToken = response.data.data.refreshToken;
          const firstName = response.data.details.firstName;
          const lastName = response.data.details.lastName;
          // const thumbnail = response.data.images.thumbnail
  
          localStorage.setItem('authToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('firstName', firstName);
          localStorage.setItem('lastName', lastName);
          // localStorage.setItem('thumbnail', thumbnail)
  
          // Cookies.set('token', accessToken, { secure: true, sameSite: 'Strict', path: '/' });
          login(accessToken, refreshToken);
  
          window.location.href = '/dashboard';
      } else {
          setError('Login failed. Access token is undefined.');
          setShowGif(false);
      }
  } catch (error) {
      setError('Invalid email or password.');
      setShowGif(false);
  } finally {
      setLoading(false);
  }
  
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="background.default">
      <Paper elevation={3} sx={{ p: 4, width: 320 }}>
        <Box display="flex" justifyContent="center" mb={2}>
          <img src={logo} alt="EaseSeat Logo" style={{ width: '100px' }} />
        </Box>
        {error && <Typography variant="body2" color="error" textAlign="center" gutterBottom>{error}</Typography>}
        <form onSubmit={handleLogin}>
          <TextField label="Email" variant="outlined" fullWidth margin="normal" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required disabled={loading} />
          <TextField label="Password" variant="outlined" fullWidth margin="normal" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required disabled={loading} />
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }} disabled={loading}>Log In</Button>
        </form>
        {showGif && (
          <Box display="flex" justifyContent="center" alignItems="center" position="fixed" top={0} left={0} width="100vw" height="100vh" bgcolor="rgba(0, 0, 0, 0.5)" style={{ backdropFilter: 'blur(5px)', zIndex: 1000 }}>
            <img src="https://media.tenor.com/sjY1bSv3vN8AAAAi/fat-kitty-cat-massage.gif" alt="Fat Kitty Massage GIF" style={{ width: '90%', maxWidth: '200px' }} />
          </Box>
        )}
      </Paper>
    </Box>
  );
}