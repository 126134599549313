import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Box, Typography, MenuItem, FormControl, InputLabel, Select, Snackbar, Alert } from '@mui/material';
import { useAuth } from 'Components/AuthContext';
import { axiosClient } from 'Config/Axios';
import { ApiEndpoints } from 'Config/ApiConstants';
import { useParams, useNavigate } from 'react-router-dom';

const NovaTechForm = () => {
  const { accessToken } = useAuth();
  const { id } = useParams();
  const [successOpen, setSuccessOpen] = useState(false);
  const navigate = useNavigate();  // For navigation
  const [bankData, setBankData] = useState({
    account_number: '',
    ifsc_code: '',
    beneficiary_name: '',
  });
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    type: '',
    legal_business_name: '',
    business_type: '',
    contact_name: '',
    profile: {
      category: '',
      subcategory: '',
      addresses: {
        registered: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          postal_code: '',
          country: '',
        },
      },
    },
    legal_info: {
      pan: '',
      gst: '',
    },
  });

  const [submitted, setSubmitted] = useState(false);
  const [centerId] = useState('');
  const [accountId, setAccountId] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);  // State to manage form disabled status
  const [error, setError] = useState(null);  // State to manage error message

  useEffect(() => {
    const fetchAccountId = async () => {
      try {
        // Replace this with the actual endpoint that returns razorpayAccountId
        const url = ApiEndpoints.GET_CENTER_ACCOUNT(id);
        const response = await axiosClient.get(url);
        const razorpayAccountId = response.data.data.razorpayAccountId;

        if (razorpayAccountId) {
          setAccountId(razorpayAccountId);
          setIsDisabled(true);  // Disable the form if razorpayAccountId is present
        }
      } catch (error) {
        console.error('Error fetching account details:', error);
      }
    };

    fetchAccountId();
  }, [id, accessToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBankDataChange = (e) => {
    const { name, value } = e.target;
    setBankData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        [name]: value,
      },
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        addresses: {
          ...prev.profile.addresses,
          registered: {
            ...prev.profile.addresses.registered,
            [name]: value,
          },
        },
      },
    }));
  };

  const handleLegalChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      legal_info: {
        ...prev.legal_info,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = ApiEndpoints.POST_ACCOUNTS(id);
      console.log('Request URL:', url);
      const response = await axiosClient.post(url, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        console.log('Success:', response.data);

        // Check if centerId and accountId exist in the response
        const accountId = response.data.data.accountId;
        console.log('accountId:', accountId);

        if (accountId) {

          setAccountId(accountId);
          setSubmitted(true); // Set submitted to true to render new form
        } else {
          console.error('centerId or accountId missing in response');
        }
      } else {
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Network Error:', error.message);
    }
  };


  const handleBankSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting bank details with centerId:', centerId, 'accountId:', accountId);

    try {
      // Construct the URL using both id and accountId
      const bankDetailsUrl = ApiEndpoints.POST_DETAILS(id, accountId);
      // console.log('Bank Details Request URL:', bankDetailsUrl);

      const response = await axiosClient.post(bankDetailsUrl, bankData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        setSuccessOpen(true);  // Open Snackbar on success
        setTimeout(() => navigate('/dashboard'), 2000);  // Redirect after 2 seconds
        // You can handle further actions after successful bank details submission
      } else {
        console.error('Bank Details Error:', response.statusText);
      }
    } catch (error) {
      console.error('Bank Details Network Error:', error.message);
    }
  };
  const handleCloseSnackbar = () => {
    setSuccessOpen(false);
  };

  if (submitted) {
    return (
      <Box component="form" onSubmit={handleBankSubmit} sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Bank Details Form
        </Typography>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Account Number"
            name="account_number"
            value={bankData.account_number}
            onChange={handleBankDataChange}
            required
            disabled={isDisabled}  // Disable field if isDisabled is true
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="IFSC Code"
            name="ifsc_code"
            value={bankData.ifsc_code}
            onChange={handleBankDataChange}
            required
            disabled={isDisabled}  // Disable field if isDisabled is true
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Beneficiary Name"
            name="beneficiary_name"  // Changed to match the `value` prop
            value={bankData.beneficiary_name}
            onChange={handleBankDataChange}
            required
            disabled={isDisabled}  // Disable field if isDisabled is true
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth disabled={isDisabled}>
            Submit Bank Details
          </Button>
        </Grid>
        <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success">
            Account details submitted successfully!
          </Alert>
        </Snackbar>
        {error && (
          <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    );
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Account Form
      </Typography>
      <Grid container spacing={2}>
        {/* Your form fields here */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Legal Business Name"
            name="legal_business_name"
            value={formData.legal_business_name}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="business-type-label">Business Type</InputLabel>
            <Select
              labelId="business-type-label"
              label="Business Type"
              name="business_type"
              value={formData.business_type}
              onChange={handleChange}
              disabled={isDisabled}
            >
              <MenuItem value="llp">LLP</MenuItem>
              <MenuItem value="ngo">NGO</MenuItem>
              <MenuItem value="other">Other</MenuItem>
              <MenuItem value="individual">Individual</MenuItem>
              <MenuItem value="partnership">Partnership</MenuItem>
              <MenuItem value="proprietorship">Proprietorship</MenuItem>
              <MenuItem value="public_limited">Public Limited</MenuItem>
              <MenuItem value="private_limited">Private Limited</MenuItem>
              <MenuItem value="trust">Trust</MenuItem>
              <MenuItem value="society">Society</MenuItem>
              <MenuItem value="not_yet_registered">Not Yet Registered</MenuItem>
              <MenuItem value="educational_institutes">Educational Institutes</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Contact Name"
            name="contact_name"
            value={formData.contact_name}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              label="Category"
              name="category"
              value={formData.profile.category}
              onChange={handleProfileChange}
              disabled={isDisabled}
            >
              <MenuItem value="it_and_software">IT and Software</MenuItem>
              <MenuItem value="services">Services</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Subcategory"
            name="subcategory"
            value={formData.profile.subcategory}
            onChange={handleProfileChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street 1"
            name="street1"
            value={formData.profile.addresses.registered.street1}
            onChange={handleAddressChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street 2"
            name="street2"
            value={formData.profile.addresses.registered.street2}
            onChange={handleAddressChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={formData.profile.addresses.registered.city}
            onChange={handleAddressChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="State"
            name="state"
            value={formData.profile.addresses.registered.state}
            onChange={handleAddressChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Postal Code"
            name="postal_code"
            value={formData.profile.addresses.registered.postal_code}
            onChange={handleAddressChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Country"
            name="country"
            value={formData.profile.addresses.registered.country}
            onChange={handleAddressChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="PAN"
            name="pan"
            value={formData.legal_info.pan}
            onChange={handleLegalChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="GST"
            name="gst"
            value={formData.legal_info.gst}
            onChange={handleLegalChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth disabled={isDisabled} >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NovaTechForm;
