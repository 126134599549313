import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Box,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { axiosClient } from 'Config/Axios';
import { ApiEndpoints } from 'Config/ApiConstants';
// import { clearAllListeners } from '@reduxjs/toolkit';

export default function ReportPage() {
  const [centerList, setCenterList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [page, setPage] = useState(1);
  const [lastFetchedId, setLastFetchedId] = useState([]);
  const [centerListArray, setCenterListArray] = useState([]); // Initialize this state
  const navigate = useNavigate();

  const fetchCenterList = async (lastId = '', pageNumber = 1) => {
    try {
      const response = await axiosClient.get(
        ApiEndpoints.GET_CENTER(2) + `&lastFetchedId=${lastId}`
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const fetchedCenters = response.data.data.centers;
      const newLastFetchedId = response.data.data.lastFetchedId ?? '';
      // console.log('newLastFetchedId', { newLastFetchedId })
      setCenterList(fetchedCenters);
      setCenterListArray(prev => {
        const newArray = prev;
        // console.log('newarray, pgnumber, ind', { newArray, pageNumber, ind: pageNumber - 1 })
        newArray[pageNumber - 1] = fetchedCenters; // Store data for the current page
        return newArray;
      });
      setLastFetchedId(prev => {
        prev[pageNumber - 1] = newLastFetchedId
        return prev
      });
    } catch (error) {
      console.error('Error fetching spa list:', error);
    }
  };

  useEffect(() => {
    fetchCenterList();
  }, []);

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowIndex(null);
  };

  const handleViewPage = () => {
    if (centerList[selectedRowIndex]) {
      const centerId = centerList[selectedRowIndex]._id;
      navigate(`/center/${centerId}`);
    }
    handleMenuClose();
  };
  // console.log({ lastFetchedId, page })

  const handleNextPage = () => {
    setPage(prevPage => {
      // console.log('prevPage : ', { prevPage })
      const newPage = prevPage + 1;
      fetchCenterList(lastFetchedId[prevPage - 1], newPage);
      return newPage;
    });
  };

  // console.log({ centerListArray, lng: centerListArray.length })
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(prevPage => {
        const newPage = prevPage - 1;
        console.log("Previous page:", newPage);

        // Set the center list to the previous page's data
        const previousCenters = centerListArray[newPage - 1] || [];
        setCenterList(previousCenters);

        return newPage;
      });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* <Typography variant="h4" component="h1" gutterBottom>
          Center List Overview
      </Typography> */}

      <Paper sx={{ p: 2, mb: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Center Inactive List
        </Typography>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <TableContainer>
          <Table aria-label="center list table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Mobile Number</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>CreatedAt</TableCell>
                <TableCell align="center">Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {centerList && centerList.length > 0 ? (
                centerList.map((center, index) => (
                  <TableRow key={center._id}>
                    <TableCell>{center.name}</TableCell>
                    <TableCell>{center.mobileNumber}</TableCell>
                    <TableCell>{center.status}</TableCell>
                    <TableCell>{center.createdAt}</TableCell>
                    <TableCell align="center">
                      <IconButton onClick={(e) => handleMenuOpen(e, index)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleViewPage}>
                          View Page
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            variant="contained"
            onClick={handlePreviousPage}
            disabled={page === 1}
          >
            Previous
          </Button>
          <Typography variant="body2" component="span">
            Page {page}
          </Typography>
          <Button
            variant="contained"
            onClick={handleNextPage}
            disabled={page < centerListArray.length ? !centerListArray.length : lastFetchedId[page - 1] === ''}
          >
            Next
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
