import React,{useState} from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Navbar from '../Navbar/Navbar'
import Sidebar from 'Components/Sidebar/Sidebar'
import { useGetUserQuery } from 'state/api'

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const UserId = useSelector((state) => state.global.UserId);
  const {data} = useGetUserQuery(UserId)
  

  return (
  <Box width="100%" height="100%" display={isNonMobile ? "flex" : "block"}>
  <Sidebar
  user
    isNonMobile={isNonMobile}
    drawerWidth="250px"
    isSidebarOpen={isSidebarOpen}
    setIsSidebarOpen={setIsSidebarOpen}
  />
  <Box flexGrow={1}>
    <Navbar
     user={data || {}}
       isSidebarOpen={isSidebarOpen}
       setIsSidebarOpen={setIsSidebarOpen}
    />
    <Outlet/>
  </Box>

  </Box>
  )
}

export default Layout