import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import moment from 'moment';
import { ApiEndpoints  } from './ApiConstants';

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const blacklistUrls = [ApiEndpoints.LOGIN];

const parseJWTTokenExpiry = (token) => {
    try {
        if (!token) {
            console.error('No token provided');
            return moment(); // Return current time if token is missing
        }

        // console.log('Token to decode:', token);
        const decodedToken = jwtDecode(token);

        if (!decodedToken || !decodedToken.exp) {
            console.error('Decoded token is invalid or missing expiration');
            return moment(); // Return current time if decoding fails
        }

        // console.log('Decoded token:', decodedToken);
        return moment.unix(decodedToken.exp);
    } catch (error) {
        console.error('Error decoding JWT token:', error.message);
        return moment(); // Fall back to the current moment if decoding fails
    }
};

// const sessionExpiryHandler = () => {
//     localStorage.removeItem('authToken');
//     localStorage.removeItem('refreshToken');
//     localStorage.removeItem('firstName');
//     localStorage.removeItem('lastName');
//     window.location.href = '/'; 
// };

const checkTokenExpired = (token) => {
    try {
        const expiryTime = parseJWTTokenExpiry(token);
        return moment().isSameOrAfter(expiryTime);
    } catch (error) {
        console.error('Error checking token expiry:', error);
        return true; 
    }
};

const refreshAccessToken = async (refreshToken) => {
    try {
        // console.log('Attempting to refresh access token with refreshToken:', refreshToken);

        // Ensure the refreshToken is not null or undefined
        if (!refreshToken) {
            throw new Error('No refresh token available');
        }

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}admin/refresh`, {
            headers: {
                'Authorization': `Bearer ${refreshToken}`,
            },
        });

        // console.log('Refresh token response:', response);

        const newAccessToken = response.data.data.accessToken;
        // console.log(newAccessToken)
        if (newAccessToken) {
            // Replace the old authToken with the new one in localStorage
            localStorage.setItem('authToken', newAccessToken);
            return newAccessToken;
        } else {
            throw new Error('No access token returned');
        }
    } catch (error) {
        console.error('Error refreshing access token:', error.response ? error.response.data : error.message);
        // sessionExpiryHandler();
        return null;
    }
};


axiosClient.interceptors.request.use(
    async (config) => {
        try {
            const accessToken = localStorage.getItem('authToken');
            const refreshToken = localStorage.getItem('refreshToken');

            if (accessToken) {
                const tokenExpired = checkTokenExpired(accessToken);
                
                // console.log('Token expired:', tokenExpired);

                if (tokenExpired) {
                    if (refreshToken) {
                        const newAccessToken = await refreshAccessToken(refreshToken);

                        if (newAccessToken) {
                            config.headers.Authorization = `Bearer ${newAccessToken}`;
                        } else {
                            return Promise.reject('Failed to refresh access token');
                        }
                    } else {
                        // sessionExpiryHandler();
                        return Promise.reject('No refresh token available');
                    }
                } else if (!blacklistUrls.includes(config.url || '')) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
            } else {
                console.error('No access token available in localStorage');
                // sessionExpiryHandler();
                return Promise.reject('No access token available');
            }
        } catch (error) {
            console.error('Error in request interceptor:', error);
            return Promise.reject(error);
        }
        return config;
    },
    (error) => {
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
    }
);
