import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Box, Typography,Snackbar,Alert } from '@mui/material';
import { useAuth } from 'Components/AuthContext';
import { axiosClient } from 'Config/Axios';
import { ApiEndpoints } from 'Config/ApiConstants';
import { useParams,useNavigate } from 'react-router-dom';

const UpdateAccount = () => {
  const { id } = useParams();
  const [successOpen, setSuccessOpen] = useState(false);
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const [error, setError] = useState(null); 
  const [accountData, setAccountData] = useState({
    phone: '',
    legal_business_name: '',
    contact_name: '',
    profile: {
      category: '',
      subcategory: '',
      addresses: {
        registered: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          postal_code: '',
          country: ''
        }
      }
    },
    legal_info: {
      pan: '',
      gst: ''
    }
  });

  const [bankData, setBankData] = useState({
    settlements: {
      account_number: '',
      ifsc_code: '',
      beneficiary_name: '',
    }
  });

  const [razorpayAccountId, setRazorpayAccountId] = useState('');
  const [razorpayProductId, setRazorpayProductId] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchAccountId = async () => {
      try {
        const url = ApiEndpoints.GET_CENTER_ACCOUNT(id);
        const response = await axiosClient.get(url);
        const razorpayAccountId = response.data.data.razorpayAccountId;
        const razorpayProductId = response.data.data.razorpayProductId;

        setRazorpayAccountId(razorpayAccountId);
        setRazorpayProductId(razorpayProductId);
       
        if (razorpayAccountId) {
          fetchAccountDetails(razorpayAccountId);
          fetchAccountUpdateDetails(razorpayAccountId, razorpayProductId);
        }
      } catch (error) {
        console.error('Error fetching Razorpay account details:', error);
      }
    };

    const fetchAccountDetails = async (razorpayAccountId) => {
      try {
        const url = ApiEndpoints.GET_ACCOUNT_DETAILS(razorpayAccountId);
        const response = await axiosClient.get(url);

        const fetchedData = response.data.data;

        // Ensure nested objects are initialized
        const filteredData = {
          phone: fetchedData.phone || '',
          legal_business_name: fetchedData.legal_business_name || '',
          contact_name: fetchedData.contact_name || '',
          profile: {
            category: fetchedData.profile?.category || '',
            subcategory: fetchedData.profile?.subcategory || '',
            addresses: {
              registered: {
                street1: fetchedData.profile?.addresses?.registered?.street1 || '',
                street2: fetchedData.profile?.addresses?.registered?.street2 || '',
                city: fetchedData.profile?.addresses?.registered?.city || '',
                state: fetchedData.profile?.addresses?.registered?.state || '',
                postal_code: fetchedData.profile?.addresses?.registered?.postal_code || '',
                country: fetchedData.profile?.addresses?.registered?.country || ''
              }
            }
          },
          legal_info: {
            pan: fetchedData.legal_info?.pan || '',
            gst: fetchedData.legal_info?.gst || ''
          }
        };

        setAccountData(filteredData);
      } catch (error) {
        console.error('Error fetching account details:', error);
      }
    };

    const fetchAccountUpdateDetails = async (razorpayAccountId, razorpayProductId) => {
      try {
        const url = ApiEndpoints.GET_BANK_DETAILS(razorpayAccountId, razorpayProductId);
        const response = await axiosClient.get(url);

        const fetchedData = response.data.data;

        // Ensure nested objects are initialized
        const filteredData = {
          settlements: {
            account_number: fetchedData.active_configuration?.settlements?.account_number || '',
            ifsc_code: fetchedData.active_configuration?.settlements?.ifsc_code || '',
            beneficiary_name: fetchedData.active_configuration?.settlements?.beneficiary_name || '',
          }
        };

        setBankData(filteredData);
      } catch (error) {
        console.error('Error fetching bank details:', error);
      }
    };

    fetchAccountId();
  }, [id]);

  // Handle input changes and directly update state for accountData
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handle nested fields
    const nameParts = name.split('.');

    setAccountData((prevData) => {
      let updatedData = { ...prevData };

      let currentField = updatedData;
      for (let i = 0; i < nameParts.length - 1; i++) {
        currentField = currentField[nameParts[i]];
        if (currentField === undefined) {
          currentField = {};
        }
      }

      currentField[nameParts[nameParts.length - 1]] = value;

      return updatedData;
    });
  };

  // Handle input changes and directly update state for bankData
  const handleBankUpdateChange = (e) => {
    const { name, value } = e.target;

    // Handle nested fields
    const nameParts = name.split('.');

    setBankData((prevData) => {
      let updatedData = { ...prevData };

      let currentField = updatedData;
      for (let i = 0; i < nameParts.length - 1; i++) {
        currentField = currentField[nameParts[i]];
        if (currentField === undefined) {
          currentField = {};
        }
      }

      currentField[nameParts[nameParts.length - 1]] = value;

      return updatedData;
    });
  };

  // Handle form submission with PUT request
  const handleSubmit = async () => {
    try {
      const url = ApiEndpoints.PUT_ACCOUNTS(razorpayAccountId);
      const response = await axiosClient.put(url, accountData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        console.log('Account Details Updated Successfully:', response.data);
        setSubmitted(true);
      } else {
        console.error('Account Details Error:', response.statusText);
      }
    } catch (error) {
      console.error('Account Details Network Error:', error.message);
    }
  };

  const handleBankUpdateSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = ApiEndpoints.PUT_BANK_DETAILS(razorpayAccountId, razorpayProductId);
      const response = await axiosClient.put(url, bankData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        setSuccessOpen(true);  // Open Snackbar on success
        setTimeout(() => navigate('/dashboard'), 2000);
      } else {
        console.error('Bank Details Error:', response.statusText);
      }
    } catch (error) {
      console.error('Bank Details Network Error:', error.message);
    }
  };
  const isDisabled = !razorpayAccountId;
  const handleCloseSnackbar = () => {
    setSuccessOpen(false);
  };

  if (submitted) {
    return (
      <Box component="form" onSubmit={handleBankUpdateSubmit} sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Bank Details Form
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Account Number"
              name="settlements.account_number"
              value={bankData.settlements?.account_number || ''}
              onChange={handleBankUpdateChange}
              required
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="IFSC Code"
              name="settlements.ifsc_code"
              value={bankData.settlements?.ifsc_code || ''}
              onChange={handleBankUpdateChange}
              required
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Beneficiary Name"
              name="settlements.beneficiary_name"
              value={bankData.settlements?.beneficiary_name || ''}
              onChange={handleBankUpdateChange}
              required
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit Bank Details
            </Button>
          </Grid>
          <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
        Account details Updated successfully!
        </Alert>
      </Snackbar>
      {error && (
        <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Update Account Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={accountData.phone || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Legal Business Name"
            name="legal_business_name"
            value={accountData.legal_business_name || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Contact Name"
            name="contact_name"
            value={accountData.contact_name || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Category"
            name="profile.category"
            value={accountData.profile?.category || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Subcategory"
            name="profile.subcategory"
            value={accountData.profile?.subcategory || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street 1"
            name="profile.addresses.registered.street1"
            value={accountData.profile?.addresses?.registered?.street1 || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street 2"
            name="profile.addresses.registered.street2"
            value={accountData.profile?.addresses?.registered?.street2 || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="City"
            name="profile.addresses.registered.city"
            value={accountData.profile?.addresses?.registered?.city || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="State"
            name="profile.addresses.registered.state"
            value={accountData.profile?.addresses?.registered?.state || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Postal Code"
            name="profile.addresses.registered.postal_code"
            value={accountData.profile?.addresses?.registered?.postal_code || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Country"
            name="profile.addresses.registered.country"
            value={accountData.profile?.addresses?.registered?.country || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="PAN"
            name="legal_info.pan"
            value={accountData.legal_info?.pan || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="GST"
            name="legal_info.gst"
            value={accountData.legal_info?.gst || ''}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            Update Account Details
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateAccount;
